import { useCallback, useEffect, useRef, useState } from 'react';
import { AdvancedMarker, APIProvider, Map, Pin, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { Marker } from '@googlemaps/markerclusterer';
import { Sheet } from 'react-modal-sheet';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import aituBridge from '@btsd/aitu-bridge';
import { Station } from './model/Station';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ShareIcon from '@mui/icons-material/Share';
import DirectionsIcon from '@mui/icons-material/Directions';
import * as amplitude from '@amplitude/analytics-browser';

const App = () => {
  // const [message, setMessage] = useState<string | undefined>(undefined);

  useEffect(() => {
    // amplitude.init('389bc32bd5bcf55c21dcc0b6b6d60be3'); // dev
    amplitude.init('7f269f305f5da7338443c8cea45a3118'); // prod
    // async function fetchData() {
    //   const user = await aituBridge.getMe();
    //   console.log(user)
    //   // amplitude.init('389bc32bd5bcf55c21dcc0b6b6d60be3', user.id); // dev
    //   amplitude.init('7f269f305f5da7338443c8cea45a3118', user.id); // prod
    // }
    // if (aituBridge.isSupported()) {
    //   fetchData();
    // }
  }, []);

  const [selectedStation, setSelectedStation] = useState<Station | undefined>();

  const handleMarkerClick = (station: Station) => {
    setSelectedStation(station);

    const eventProperties = {
      provider_name: station.provider?.name,
      station_id: station.id,
      station_title: station.place.title,
      station_location: `${station.location.lat}, ${station.location.lng}`,
    };
    amplitude.track('HomeScreen_show_station_info', eventProperties);
  }

  const handleShareClick = async () => {
    if (!selectedStation) return;

    if (aituBridge.supports('share')) {
      const result = await aituBridge.share(`https://evpoint.kz/stations?id=${selectedStation?.id}`);

      const eventProperties = {
        provider_name: selectedStation.provider?.name,
        station_id: selectedStation.id,
      };
      amplitude.track('ChargingStationInfoScreen_open_share_app_chooser', eventProperties);
    }
  }

  const handlePaymentClick = async () => {
    if (!selectedStation || !selectedStation.paymentLink) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(selectedStation.paymentLink);

      const eventProperties = {
        provider_name: selectedStation.provider?.name,
        station_id: selectedStation.id,
      };
      amplitude.track('ChargingStationInfoScreen_open_provider_app', eventProperties);
    }
  }

  const handleOpenSupportClick = async () => {
    if (!selectedStation || !selectedStation.provider?.supportLink) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(selectedStation.provider.supportLink);

      const eventProperties = {
        provider_name: selectedStation.provider?.name,
        station_id: selectedStation.id,
      };
      amplitude.track('ChargingStationInfoScreen_open_provider_support', eventProperties);
    }
  }

  const handleOpenNavigationClick = async () => {
    if (!selectedStation) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(`https://maps.google.com/?daddr=${selectedStation.location.lat},${selectedStation.location.lng}`);

      const eventProperties = {
        provider_name: selectedStation.provider?.name,
        station_id: selectedStation.id,
      };
      amplitude.track('ChargingStationInfoScreen_open_provider_support', eventProperties);
    }
  }

  // prod
  // <APIProvider apiKey={'AIzaSyAU6yipBS63rDWhqb80IZpKkfEXK0t-kEs'}>

  // dev AIzaSyDUGD5jeY_5x6pTvjL9rHn0zP6X1FQnq_A
  return (
    <>
      <APIProvider apiKey={'AIzaSyAU6yipBS63rDWhqb80IZpKkfEXK0t-kEs'}>
        <Map
          style={{ width: '100vw', height: '100vh' }}
          defaultCenter={{ lat: 51.169392, lng: 71.449074 }}
          defaultZoom={12}
          minZoom={3}
          gestureHandling={'greedy'}
          fullscreenControl={false}
          onClick={() => setSelectedStation(undefined)}
          disableDefaultUI={false}
          mapTypeControl={false}
          streetViewControl={false}
          mapId={'3dfe11e1ec4e9a3b'}
        >
          <StationMarkers stations={stations} onMarkerClick={handleMarkerClick} />
        </Map>
      </APIProvider>

      <Sheet detent='content-height' isOpen={selectedStation !== undefined} onClose={() => setSelectedStation(undefined)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Box marginX={2} marginBottom={2}>

              <Typography variant='h6'>{selectedStation?.place.title}</Typography>
              <Stack direction="row">
                <Typography variant='caption'>{selectedStation?.place.address}</Typography>
                {selectedStation?.place.city && <Typography variant='caption'>, {selectedStation?.place.city}</Typography>}
              </Stack>
              {selectedStation?.wattage && <Typography variant='subtitle1'>Мощность: {selectedStation?.wattage} кВт</Typography>}

              <Stack overflow={'auto'} direction="row" spacing={2} marginTop={1}>
                <Button variant="contained" disableElevation onClick={handlePaymentClick}>Оплатить</Button>
                <IconButton size="medium" onClick={handleOpenNavigationClick}><DirectionsIcon /></IconButton>
                <IconButton size="medium" onClick={handleShareClick}><ShareIcon /></IconButton>
                {selectedStation?.provider?.supportLink && <IconButton size="medium" onClick={handleOpenSupportClick}><HeadsetMicIcon /></IconButton>}
              </Stack>

            </Box>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      {/* <Sheet detent='content-height' isOpen={message !== undefined} onClose={() => { }}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Typography>{message}</Typography>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet> */}
    </>
  )
};

const stations: Station[] = [
  {
    id: '3',
    provider: { id: '1', name: 'Qoshqar Energy', supportLink: 'https://api.whatsapp.com/send/?phone=77760527788&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'Qoshqar Energy', address: 'пр. Акжол, 37/1', city: 'Астана' },
    location: { lat: 51.18438484630995, lng: 71.4538 },
    paymentLink: 'https://kaspi.kz/pay/CarWashQr?service_id=3829&5695=CP_7',
    wattage: '22'
  },
  {
    id: '2',
    provider: { id: '1', name: 'Qoshqar Energy', supportLink: 'https://api.whatsapp.com/send/?phone=77760527788&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'БЦ Нурлы Орда', address: 'пр. Кабанбай батыр, 11/5', city: 'Астана' },
    location: { lat: 51.139674, lng: 71.412646 },
    wattage: '22',
    paymentLink: 'https://kaspi.kz/pay/CarWashQr?service_id=3829&5695=CP_5'
  },
  {
    id: '1',
    place: { title: 'Магазин Башмачок', address: 'ул. Кудайбердиулы, 17а', city: 'Астана' },
    location: { lat: 51.157378, lng: 71.497741 },
    paymentLink: 'https://lk.tol.kz/cplink?op=01178400_041e_4248_9469_5b5f7b641e5e&cpn=060010001',
    wattage: '60'
  },
  {
    id: '120080001',
    provider: { id: '1', name: 'Tol.kz', supportLink: 'https://api.whatsapp.com/send/?phone=77079544040&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'Акимат', address: 'Казыбек Би, за Акиматом', city: 'Тараз' },
    paymentLink: 'https://lk.tol.kz/cplink?op=01178400_041e_4248_9469_5b5f7b641e5e&cpn=120080001',
    location: { lat: 42.89753, lng: 71.381271 }
  },
  {
    id: '221210910101',
    provider: { id: '1', name: 'Tol.kz', supportLink: 'https://api.whatsapp.com/send/?phone=77079544040&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'Royal Tulip', address: 'ул. Оспанова, д. 401/2', city: 'Алматы' },
    location: { lat: 43.203232, lng: 76.977012 },
    paymentLink: 'https://lk.tol.kz/cplink?op=01178400_041e_4248_9469_5b5f7b641e5e&cpn=221210910101',
    wattage: '120'
  },
  {
    id: '120020003',
    provider: { id: '1', name: 'Tol.kz', supportLink: 'https://api.whatsapp.com/send/?phone=77079544040&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'Mercur', address: 'ул. Майлина, д. 79/1', city: 'Алматы' },
    location: { lat: 43.337813, lng: 76.981576 },
    paymentLink: 'https://lk.tol.kz/cplink?op=01178400_041e_4248_9469_5b5f7b641e5e&cpn=120020003',
    wattage: '120'
  },
  {
    id: '120010001',
    provider: { id: '1', name: 'Tol.kz', supportLink: 'https://api.whatsapp.com/send/?phone=77079544040&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5!%0A%0A%D0%9F%D0%B8%D1%88%D1%83+%D0%B8%D0%B7+%D0%BF%D1%80%D0%B8%D0%BB%D0%BE%D0%B6%D0%B5%D0%BD%D0%B8%D1%8F+evPoint.kz.%0A%0A&type=phone_number&app_absent=0' },
    place: { title: 'Акимат', address: 'пр. Сарыарка, 13', city: 'Астана' },
    location: { lat: 51.164762, lng: 71.407625 },
    paymentLink: 'https://lk.tol.kz/cplink?op=01178400_041e_4248_9469_5b5f7b641e5e&cpn=120010001',
    wattage: '120'
  },
];

const StationMarkers = (props: { stations: Station[], onMarkerClick: (station: Station) => void }) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [id: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  const handleClick = useCallback((ev: google.maps.MapMouseEvent, station: Station) => {
    if (!map) return;
    if (!ev.latLng) return;
    console.log('marker clicked:', ev.latLng.toString());
    map.panTo(ev.latLng);
    props.onMarkerClick(station);
  }, [map, props]);

  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers(prev => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {props.stations.map((station: Station) => (
        <AdvancedMarker
          key={station.id}
          position={station.location}
          ref={marker => setMarkerRef(marker, station.id)}
          clickable={true}
          onClick={event => handleClick(event, station)}
        >
          <Pin background={'#EA6035'} glyphColor={'#F1F614'} borderColor={'#EA6035'} />
        </AdvancedMarker>
      ))}
    </>
  );
};


export default App;
